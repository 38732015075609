@use '@/styles/global/Vars.scss';

.diagnostic-container {
	text-align: center;
	margin-top: 10px;
	.diagnostic-title {
		background: Vars.$uma-primary;
		color: Vars.$white-color;
		font-weight: bold;
		font-size: 1rem;
		margin: 0;
		padding: 8px;
		border-radius: 5px;
		text-align: left;
	}
	.diagnostic-text-container {
		padding: 5px 15px;
		text-overflow: ellipsis;
		white-space: nowrap;
		position: relative;
		overflow: hidden;
		text-align: left;
		font-size: 0.8rem;
		.diagnostic-text {
			overflow-wrap: break-word;
			color: Vars.$uma-text;
			font-size: 1rem;
		}
		.diagnostic-progressbar {
			position: relative;
			margin-bottom: 10px;
			background: Vars.$white-color;
			border-radius: 7px;
			height: 25px;
			overflow: hidden;
			.progress-striped .progress-bar,
			.progress-bar-striped {
				background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
				background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
				background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
				background-size: 40px 40px; 
			}
			.progress-bar {
				float: left;
				position: absolute;
				top: 0;
				height: 100%;
				font-size: 12px;
				line-height: 20px;
				color: #fff;
				text-align: center;
				padding: 5px;
				background-color: #337ab7;
				-webkit-box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
				box-shadow: inset 0 -1px 0 rgba(0, 0, 0, 0.15);
				-webkit-transition: width 0.6s ease;
				-o-transition: width 0.6s ease;
				transition: width 0.6s ease; 
			}
		}
	}
	p{
		padding: 6px 8px;
	}
}

.progress-striped .progress-bar,
.progress-bar-striped {
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 7px 0 0 7px;
background: Vars.$uma-primary;
background-image: -webkit-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
background-image: -o-linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
background-size: 40px 40px; }