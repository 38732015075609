@use '../../../../styles/global/Vars.scss';


.paymentRejected__container{
    button{
        display: block;
        margin: 16px auto;
    }
}

.paymentRejected__reasons{
    padding: 32px 16px;
    display: flex;
    flex-wrap: wrap;
    label{
        min-width: 80px;
        text-align: center;
        padding: 8px;
        border: 1px solid Vars.$deep-blue;
        border-radius: 4px;
        font-weight: bold;
        margin: 10px;
        color: Vars.$deep-blue;
        cursor: pointer;
    }
    .selected{
        background: Vars.$uma-primary;
        color: Vars.$white-color;
    }
}


.paymentRejected__OtherReason{
    margin: 32px 3%;
}

.attached-title{
    padding: 0 8px
}

.files-list {
    width: 100%;
}

.file-row {
    display: flex;
    justify-content: space-between;
    padding: 8px 0;
    .file-name{
        display: flex;
    }
}

.file-line {
    opacity: 30%;
}

.viewerChild-container{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0,0,0,0.3);
    z-index: 3;
    .closeIcon-container{
        display: flex;
        justify-content: flex-end;
        .closeIcon-border{
            background-color: #323639;
            border-radius: 50%;
            padding: 4px;
            border-style: solid;
            border-color: white;
            border-width: 2px;
            z-index: 4;
        }
    }

    .iframe-container {
        overflow: hidden;
        padding-top: 56.25%;
        position: relative;
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-width: 100%;
            max-height: 100%;
        }
    }

    .loader-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }
} 


.selectViewContainer{
    margin: 0 32px;
    max-width: 900px;
}