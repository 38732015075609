
.page__Handler_table, 
.page__Handler_div{
	height: 45px;
	padding: 8px;

		span{
			margin: 0 5px;
			cursor: pointer;
			
		}
		i{
			color: #1E1E1E;
		}
 } 

.page__Handler_table{
	position: relative;
	.page__Handler_td{
		position: absolute;
		left: 50%;
		bottom: 0%;
		transform: translate(-50%, -35%);
	}
}

.page__Handler_div{
	display: flex;
	justify-content: center;
	align-items: center;
	.page__Handler_element{

	}
}
.page_Handler__currentPage{
	background-color: #0A6DD7;
	color: white;
	font-style: normal;
	font-weight: bold;
	font-size: 16px;
	line-height: 22px;
	padding: 5px;
	border-radius: 5px;
}
.page_Handler__prevPage, 
.page_Handler__firstPage,
.page_Handler__nextPage,
.page_Handler__susPoints,
.page_Handler__finalPage{
	color: #1E1E1E;
}
