@use '@/styles/global/Vars.scss';

.form {
	flex-direction: column;
	position: relative;
	.form--label {
		position: absolute;
		left: 20px;
		pointer-events: none;
		color: Vars.$when-dark;
		font-size: 16px;
		top: 0;
		transition: transform 0.2s linear;
		transform: translateY(30px);
		&.up {
			transform: translateY(5px);
		}
	}
	.form--eyePass {
		position: absolute;
		right: 30px;
		bottom: 10px;
	}
	.form--select {
	 position: absolute;
	 bottom: -28px;
	 width: 90%;
	 &.focus {
		 outline: none;
	 }
	}

	.form--input {
		margin-top: 30px;
		width: 90%;
		padding: 10px;
		height: 25px;
		border: none;
		border-bottom: 2px solid Vars.$uma-button;
		transition: transform 0.3s linear;
		&.invalid {
			border-bottom: 2px solid Vars.$uma-error;
		}
		&:focus {
			outline: none;
			border-bottom: 2px solid Vars.$blue-button;
		}
	}
}


.conditions {
	display: flex;
	flex-direction: column; 
	align-items: flex-start;
	padding: 15px 5% 0 5%;
	position: relative;
	margin: 0 auto;
	width: 400px;
	:nth-child(1) {
		margin: 5px 0;
	}
	.conditions__group {
		&:nth-child(1) {
			margin: 0;
		}
		.characters {
			margin-left: 10px;
			&::before {
				content: '';
				display: block;
				width: 18px;
				height: 18px;
				margin-top: 3px;
				border-radius: 50%;
				border: 1px solid Vars.$uma-primary;
				background-color: Vars.$white-color;
				position: absolute;
				left: 35px;
			}
		}
		.done {
			left: 35px;
			top: 6px;
			font-size: 2rem;
			color: Vars.$uma-primary;
			position: absolute;
			&.number {
				top: 40px;
			}
			&.letter {
				top: 74px;
			}
		}
	}
}

// Buttons

.action-btn{
	width: 80%;
	height: 44px;
	font-weight: bold;
	border-radius: 5px;
	border: none;
	background-color: Vars.$uma-primary;
	color: Vars.$white-color;
	text-transform: uppercase;
	margin: 10px auto;
	cursor: pointer;
	&:nth-child(1) {
		margin-top: 10px;
	}
	&.space {
		margin-top: 50px;
	}
	// &.white {
	//	 background-color: Vars.$white-color;
	//	 color: Vars.$uma-primary;
	//	 box-shadow: 0px 2px 6px 3px rgba(73, 73, 73, 0.25);
	// }
}


// Buttons login

.login__buttonGroup {
	display: flex;
	justify-content: center;
	margin-top: 10px;
	white-space: nowrap;
	&.column {
		flex-direction: column;
		align-items: center;
	}

	&.center {
		text-align: center;
		.login__button {
			overflow: hidden;
			&.large {
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 0 2rem;
				img {
					position: static;
				}

				p {
					position: static;
				}
			}
		}
	}

	.login__button {
		margin: 0 15px;
		border: none;
		width: 60px;
		height: 60px;
		border-radius: 50%;
		background-color: Vars.$white-color;
		box-shadow: 0px 2px 5px 0 Vars.$lighter-grey;
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		&.large {
			border-radius: 5px;
			width: 80%;
			height: 40px;
			margin-top: 12px;
			position: relative;
			padding: 0 10px;
			p {
				margin: 0 10px;
				font-size: 16px;
				bottom: 7px;
				position: absolute;
				left: 60px;
			}
			img {
				width: 25px;
				bottom: 7px;
				position: absolute;
				left: 20px;
				display: block;
			}
			&:nth-last-child(1) {
				img {
					padding-bottom: 3px;
				}
			}
		}
		img {
			width: 30px;
		}
	}
}

// Text And Link

.textAndLink {
	cursor: pointer;
	margin: 15px 0;
	color: Vars.$uma-primary;
	text-align: center;
	&.space {
		margin-top: 50px;
	}
	p {
		margin: 0;
	}
	span {
		font-weight: bold;
		border-bottom: 1px solid Vars.$uma-primary;
	}
}

// Stepper

.stepper {
	display: flex;
	justify-content: center;
	align-items: center;
	margin: 10px 0 50px 0;
	max-width: 80%;
	.derivation__bar {
		width: 2px;
		height: 20px;
		background-color: aqua;
		color: aqua;
	}
	.step {
		text-align: center;
		position: relative;
		width: 80%;
		&::before, &::after {
			content: '';
			position: absolute;
			height: 2px;
			width: 100%;
			background-color: Vars.$uma-input;
			top: 15px;
			left: 100%;
		}
		&::before {
			content: '';
			width: 7px;
			height: 7px;
			border-radius: 50%;
			background-color: Vars.$uma-input;
			position: absolute;
			top: 13px;
		}
		&.complete::before, &.complete::after {
			background-color: Vars.$uma-primary;
		}
		&.circle::before {
			padding: 6px;
			top: 7px;
			top: 10px;
			z-index: 1;
			background-color: Vars.$uma-primary;
		}
		&:last-child::after {
			display: none;
		}
	}
}

// Calendar

.modal__dni {
	display: block;
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100vh; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
	.modal__container-dni {
		background-color:Vars.$white-color;
		max-width: 90%;
		margin: 100px auto;
		border-radius: 5px;
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		display: flex;
		flex-direction: column;
		.modal__img {
			width: 100px;
			margin: 20px 0;
		}
		.modal__text {
			color: Vars.$uma-text;
			font-size: 18px;
			margin: 30px 0;
		}
		.actionModal__btns {
			display: flex;
			justify-content: space-evenly;
			margin: 20px 0;
			.button-action {
				margin: 0 10px;
				border: none;
				border-radius: 5px;
				height: 35px;
				width: 120px;
				color: Vars.$white-color;
				&.cancel {
					background-color: Vars.$uma-error;
				}
				&.next {
					color: Vars.$uma-primary;
					border: 1px solid Vars.$uma-primary;
				}
			}
		}
	}
}

.calendar__container {
	display: block;
	position: fixed; 
	z-index: 1; 
	left: 0;
	top: 0;
	width: 100%; 
	height: 100vh; 
	overflow: auto; 
	background-color: rgb(0,0,0);
	background-color: rgba(0,0,0,0.4);
	.modal__container {
		background-color:Vars.$white-color;
		max-width: 90%;
		margin: 100px auto;
		border-radius: 5px;
		position: relative;
		box-sizing: border-box;
		overflow: hidden;
		.calendar__actions {
			padding: 15px 0;
			display: flex;
			justify-content: space-evenly;
		 .calendar__actions-btn {
			border: none;
			border-radius: 5px;
			padding: 5px 20px;
			background-color: Vars.$white-color;
			&.done{
				background-color: Vars.$uma-primary;
				color: Vars.$white-color;
			}
		 }
		 .calendar__actions-btn-close {
			 position: absolute;
			 top: 3px;
			 right: 10px;
			 font-size: 22px;
			 font-weight: bold;
			 border: none;
			 background-color: Vars.$white-color;
		 }
		 .rdrCalendarWrapper {
			 .rdrMonthAndYearWrapper {
				 max-width: 100px;
			 }
		 }
		}
	}
}


