@use '@/styles/global/Vars.scss';

.antecedentes_container {
	.antecedentes_label{
		font-size: 1.3rem;
		margin-bottom: 15px;
	}

	.antecedentes_section{
		position: relative;
		.antecedentes_header{
			z-index: 1;
			height: 30px;
			.antecedentes_eyelash{
				position: absolute;
				border: 1px solid rgba(Vars.$uma-primary, 0.3);
				border-bottom: none;
				background: Vars.$white-color;
				border-radius: 6px 6px 0 0;
				padding: 8px 16px;
				font-size: 0.8rem;
				cursor: pointer;
			}
			.antecedentes_eyelash:nth-child(1){
				left: 0%;
			}
			.antecedentes_eyelash:nth-child(2){
				left: 85px;
			}
			.antecedentes_eyelash:nth-child(3){
				left: 320px;
			}
			.antecedentes_eyelash:nth-child(4){
				left: 480px;
			}
			.active{
				background-color: Vars.$white-color;
				color: Vars.$uma-primary;
				border-bottom: none;
			}
		}
		.antecedentes__view{
            position: relative;
            border-top: 1px solid rgba(Vars.$uma-primary, 0.1);
            background: Vars.$white-color;
            max-height: 50vh;
            padding: 8px;
            border-radius: 0 5px 5px 5px;
            z-index: 0;
            .antecedentes_textarea,
            .antecedentes__othersUsers,
            .self__container{
                overflow: auto;
                height: 100%;
                min-height: 200px;
                max-height: 445px;
                padding: 6px;
                border: 1px solid Vars.$data-grey;
                border-radius: 5px;
                margin-bottom: 5px;
                .title_survey{
                    padding:5px;
                    font-weight: 700;
                }
                .text_survey{
                    padding:5px
                }
            }
            .withTags{
                height: 85%;
            }
            .antecedentes_textarea{
                transition: none;
                width: 100%;
                text-align: left;
            }
            
            .antecedentes_textarea:focus{
                outline: none !important;
                border: 2px solid Vars.$uma-primary-light !important;
            }
            .active{
                border: 2px solid Vars.$uma-primary !important;
            }

            .antecedentes__othersUsers{
                text-align: left;
                border-radius: 0 5px 5px 5px;
                border: 1px solid #CACACA;
                overflow: auto;
                background-color: #f7f7f7;
                    .antecedentes__nav{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 10px;
                    .antecedentes_importBtn{
                        background-color: transparent;
                        border: none;
                        color: Vars.$primary-color;
                        font-size: 1rem;
                        cursor: pointer;
                    }
                    .antecedentes_filtro{
                        select{
                            padding: 6px;
                            margin-bottom: 0.5rem;
                            margin-left: 0.5rem;
                            border: 1px solid #CACACA !important;
                            text-transform: capitalize;
                            option{
                                text-transform: capitalize;
                            }
                        }
                    }
                }
                .antecedentes_card{
                    display: flex;
                    margin-bottom: 25px;
                    .antecedentes_eyelashContent{
                    display: flex;
                    flex-direction: column;
                    border-radius: 6px;
                    padding: 10px;
                    width: 75%; 
                    box-shadow: 0px 2px 4px 1px rgba(99, 99, 99, 0.25);
                    background-color: white;
                    min-height: 125px;
                    max-height: 250px;
                    overflow-y: auto;
                    .antecedentes_cardTitle{
                        position: relative;
                        padding: 5px;
                        margin-bottom: 5px;
                        display: flex;
                        justify-content: space-evenly;
                        h4{
                        font-size: 1rem;
                        text-align: center;
                        text-transform: capitalize;
                        padding: 0;
                        }
                        h5{
                            font-size: 0.8rem;
                            text-align: center;
                            padding: 0;

                        }
                    }
                    .antecedentes_cardTitle::after{
                        border-bottom: 1px solid #cacaca;
                        margin: 0 auto;
                        width: 100%;
                        position: absolute;
                        left: 0%;
                        bottom: 0;
                        height: 100%;
                        z-index: 2;
                        content: "";
                    }
                    span{
                        font-size: 0.9rem;
                        overflow-y: auto;
                    }
                    }
                    .antecedentes_eyelashImport{
                        padding: 10px 0 10px 20px;
                        color: #0A6DD7;
                        cursor: pointer;
                        max-width: 20%;
                    }
                }
            }

            .self__item{
                display: block;
                padding: 5px;
                .self__item--output{
                    svg{
                    margin-left: 5px;
                    }
                    
                }
                .self__condition{
                    margin-left: 5px;
                }
            }
            .antecedentes__tagsContainer{
                padding: 8px 4px;
                overflow-y: auto;
                max-height: 100px;
                .antecedentes__label{
                    color: Vars.$uma-text;
                    font-size: 1rem;
                }
                .antecedentes__tag{
                    display: inline-block;
                    padding: 5px;
                    margin: 0 5px;
                    background-color: Vars.$uma-secondary;
                    color: white;
                    font-size: 1rem;
                    border-radius: 5px;
                }
            }
        }

		.showing{
            visibility:visible;
            z-index: 1;
            position: relative;
		}

		.antecedentes_buttonContainer{
            width: 100%;
            margin: 15px 0;
            button{
                float: right;
            }
	    }
	}

	.antecedentes__DeleteMessage{
        display: flex;
        align-items: center;
        justify-content: space-around;
        position: absolute;
        bottom: -20%;
        right: 40%;
        margin: 0 auto;
        z-index: 1;
        background: #1E1E1E;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 6px;
        width: 355px;
        p{
            margin: 0;
            color: white;
            padding: 10px;
        }
        button{
            background: transparent;
            border: none;
            color: #67B0FF;
            padding: 10px;
            outline: none;
        }
	}
}