@use '@/styles/global/Vars.scss';

.inputIcon {
	position: absolute;
	font-size: 15px;
	right: 25px;
}

.pdfRecipeModal{
	margin-top: 16px;
	.back{
		color: blue;
		margin: 8px;
		text-align: left;
		display: flex;
	}
}

.recipe {
	position: relative;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: nowrap;
	justify-content: flex-start;
	background-color: Vars.$white-color;
	border-radius: 6px;
	padding: 5px;
	header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px;
		.quit_btn{
			cursor: pointer;
			svg{
				color: #0A6DD7;
				font-size: 1.5rem;
			}
		}
	}
	.row {
		margin-left: 0px !important;
		margin-right: 0px !important;
	}
	.recipe__title--news {
		padding: 20px;
		font-size: 1rem;
	}
	
	.recipe__container{
		display: flex;
		align-items: center;
		justify-content: space-around;
		
		input{
			flex-basis: 1;
			width: 30% !important;
		}
		button{
			margin: 0 auto 10px auto;
			outline: none;
		}
		.recipe__container--input{
			width: 70%;
			padding: 8px;
			}
	}

	.recipe__container_saveBtn{
		margin: 0 auto;
		text-align: center;
		.recipe__thirdRow{
			width: 94%;
			display: flex;
			align-items: center;
			justify-content: space-around;
			background: rgba(Vars.$uma-primary, 0.1);
			padding: 20px;
			margin: 0 auto;
			border-radius: 15px;
			.recipe__container--btnSuccess{
				display: flex;
				align-content: center;
				justify-content: center;
				.history-more-btn{
					outline: none;
					margin-right: 5px;
				}
			}
			.recipe__container--btnWatchRecipe{
				color: #fff;
				padding: 8px 24px;
				border-radius: 15px;
				border: none;
				background: Vars.$uma-secondary-dark;
				font-weight: bold;
				font-size: 1rem;
				svg{
					font-size: 1.3rem;
					margin-right: 5px;
				}
			}
		}
		.recipe__signRow{
			display: flex;
			justify-content: center;
			padding: 30px 0;
			text-align: center;
			img{
				width: 300px;
				object-fit: contain;
			}
		}
		.checkout{
			position: relative;
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 8px;
			p{
				margin: 0;
			}
			svg{
				font-size: 1.4rem;
				margin-right: 5px;
			}
		}
		.checkout::after{
			content: "";
			top: 0;
			position: absolute;
			width: 80%;
			margin: 0 auto;
			height: 1px;
			border-top: 1px solid #CACACA;
		}
		.recipe__infoRow{
			padding: 10px 20px;
			ul{
				padding: 0 10px;
				li{
					list-style: circle;
				}
			}
		}
	}
	.recipe__container--checkbox{
		display: flex;
		align-items: center;
		justify-content: flex-start;
		margin: 10px;
		label {
			margin: 0 10px;
		}
	}
}

.recipeToprint {
	width: 600px;
	height: 100%;
	margin-left: auto;
	margin-right: auto;
	font-size: 22px;
	padding: 0;
	display: none;
	.recipeToPrint__container {
		width: inherit;
		margin: 15px 0;
		&.logo_container {
			display: flex;
			justify-content: center;
			align-items: center;
			padding-right: 0;
			margin: 10px auto;
		}
		.recipeToPrint__container--logo {
			width: 200px;
			height: 200px;
		}
		.recipeToPrint__container--logo.uma {
			width: 150px;
			height: 150px;
			margin-bottom: -10px;
		}
		.recipeToPrint__container--logo.ecuador_min {
			width: 350px;
		}
		&:nth-child(2) {
			margin: 0;
			padding-right: 0;
		}
		.recipeToPrint__container--title {
			h4 {
				padding: 0;
				margin: 0;
			}
			padding: 0;
			margin: 0;
			text-align: center;
			font-size: 1.6em;
		}
		.recipeToPrint__container--list {
			padding-top: 30px;
			font-size: 0.8em;
			padding-left: 20px;
			// li {
				// &::before{
				//	content: "→";
				// position: relative;
				// right: 10px;
				// bottom: 5px;
				// }
				// margin-top: 5px;
			// }
		}
		.recipeToPrint__container--table {
			width: 100%;
			font-size: 0.8em;
			margin-top: 15px;
			thead {
				th {
					font-weight: bold;
				}
			}
			tbody {
				td {
					font-size: 1em;
				}
			}
		}
		.recipeToPrint__container--recipeItem {
			padding-left: 20px;
			margin-top: 10px;
			p {
				line-height: 20px;
				font-size: 0.8em;
			}
		}
	}
	.recipeToPrint__bottomContainer {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		text-align: center;
		.recipeToPrint__bottomContainer--barcode {
			display: flex;
			flex-direction: column;
			text-align: center;
			margin-bottom: 20px;
			&:first-child {
				position: relative;
				right: 50px;
			}
			&:last-child {
				position: relative;
				left: 50px;
			}
			&.position_center {
				right: 0;
				left: 0;
				margin-left: auto;
				margin-right: auto;
			}
		}
		.recipeToPrint__bottomContainer--doctorData {
			margin-top: 5px;
			font-size: 0.9em;
		}
		.recipeToPrint__bottomContainer--firm {
			img {
				width: 150px;
				height: 150px;
			}
		}
		.recipeToPrint__bottomContainer--mail {
			width: 100%;
			border-top: 1px solid rgba($color: #000000, $alpha: 0.35);
			padding-top: 25px;
			font-size: 1em;
			margin-left: auto;
			margin-right: auto;
		}
	}
}

.recipeToPrintUp {
	padding: 50px 100px;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	position: relative;
	.recipeToPrintUp__container {
		width: 100%;
		display: flex;
		justify-content: space-between;
		align-items: baseline;
		margin-top: 25px;
		&:last-child {
			display: flex;
			flex-direction: column;
			position: absolute;
			bottom: 0;
			left: 50%;
			transform: translate(-50%, 0);
			width: calc(100vw - 190px);
		}
		.recipeToPrintUp__container--title {
			img {
				width: 200px;
				position: relative;
				bottom: 40px;
			}
		}
		.recipeToPrintUp__containerBottom {
			width: inherit;
			display: flex;
			justify-content: space-between;
			&:first-child {
				margin-bottom: 100px;
			}
			.recipeToPrintUp__containerBottom--doctorData {
				&:last-child {
					position: relative;
					text-align: right;
				}
				img {
					position: absolute;
					bottom: 70px;
					right: 0;
					width: 150px;
					height: 150px;
				}
			}
			&:last-child {
				border-top: 1px solid #000;
				width: inherit;
				display: flex;
				justify-content: center;
				margin-bottom: 45px;
				padding-top: 25px;
				font-size: 30px;
			}
		}
	}
	p {
		padding: 0 !important;
		margin: 0 !important;
		font-size: 25px !important;
	}
}



.recipe__selectLogo{
	width: 100%;
	padding: 5px 20px;
	margin: 15px 0;
	.recipe__container--label{
		margin: 8px;
	}
	.recipe__selectLogo--container{
		position: relative;
		display: flex;
		justify-content: space-between;
		align-items: center;
		.recipe__selectMedicine{
			width: 60%;
			padding: 8px;
			border: 1px solid #CACACA !important;
			background: #fff !important;
			border-radius: 15px;
		}
		.recipe__container--imgPreview{
				height: 70px;
				margin-left: 10px;
				right: 20%;
				bottom: -40%;
		}
	}
}

.recipe__PatientOS{
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 5px 0;
	.recipe__PatientOS--inputContainer{
		display: flex;
		flex-direction: column;
		width: 30%;
		padding: 5x 15px;
		border-radius: 5px;
		border: 1px solid #CACACA !important;
		input{
			background: #fff !important;
			height: 40px !important;
			border-radius: 15px;
			text-align: left;
		}
	}
	.obrasocial{
		width: 40%;
		.obrasocial_options{
		display: flex;
		align-content: center;
		.recipe__container--input{
			width: 70%;
			}
		.obrasocial_checkbox{
			display: flex;
			flex-direction: row-reverse;
			align-content: center;
			justify-content: center;
				label{
				margin-bottom: 0;
				padding-top: 8px;
				}
				input{
					margin: 0 5px;
				}
			}
		}

	}
}

.recipe__addDrugs {
	margin: 16px;
	color: Vars.$uma-primary;
	cursor: pointer;
	svg {
		font-size: 1.3rem;
	}
}

.recipe__AddDrug{
	width: 100%;
	display: flex;
	padding: 5px 0;
	flex-direction: column;
	.recipe__firstRow{
		display: flex;
		margin-bottom: 5px;
		.recipe__AddDrug--box{
			flex-direction: column;
			width: 22%;
			input{
				width: 100%;
				background: #fff !important;
				height: 40px !important;
				border-radius: 5px;
				text-align: left;
				border: 1px solid #CACACA !important;
			}
		}
		.selector{
			width: calc(78% - 10px);
			margin-right: 10px;
			.css-1pahdxg-control{
				height: 40px !important;
				border-radius: 15px !important;
				.css-1hwfws3{
					height: 40px;
					.css-1wa3eu0-placeholder{
						margin-left: 10px;
					}
					.css-b8ldur-Input{
						height: 20px;
						margin-bottom: 5px;
						overflow: hidden;
					}
					
				}
			}
			.css-yk16xz-control{
				border-radius: 15px !important;
				height: 40px;
				.css-1hwfws3{
					.css-1wa3eu0-placeholder{
						margin-left: 10px;
					}
					.css-b8ldur-Input{
						height: 20px;
						margin-bottom: 5px;
						overflow: hidden;
					}
					
				}
			}
		}
	}
	.recipe__secRow{
		display: flex;
		flex-direction: column;
		textarea{
			width: 100%;
			border-radius: 5px;
			padding: 10px;
			text-align: left;
		}
	}
}

.recipe__medicinesList{
	width: 90%;
	border-bottom: 1px solid #CACACA;
	border-top: 1px solid #CACACA;
	margin: 10px auto;
	max-height: 130px;
	overflow-y: auto;
	display: block;
	.recipe__medicines--item{
		display: flex;
		width: 100%;
		justify-content: center;
		p{
			margin: 10px 0;
		}
		.times{
			margin: 0 10px;
			width: 50px;
			background-color: transparent;
			border: none;
			outline: none;
			color: #ef5350;
			font-size: 16px;
		}
	}
}

.medikitSignButton__container{
	display: flex;
	justify-content: center;
}

.medikitDisclaimer__container{
	padding: 15px;
}