@use '@/styles/global/Vars.scss';

.signup__container{
	.signup__title{
		font-size: 25px;
		font-weight: bold;
		color: Vars.$uma-primary;
		margin: 10px 10px;
		text-align: center;
		.signup__logo{
			display: block;
			margin: 10px auto;
			width: 100px;
		}
	}
	.signup__data{
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 80%;
		padding: 15px;
		margin: 0 auto;
		border-radius: 4px;
		input[type='checkbox'] {
			width: 20px;
		}
		.signup__data-container{
			margin: 15px 10px;
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			align-items: center;
			justify-content: flex-start;
			width: 500px;
			label{
				margin-right: 8px;
				margin-bottom: 0;
				width: 250px;
				text-align: left;
			}
			.special-label{
				display: none;
			}
			.signup__data-container--label {
				text-align: left;
			}
			.signup__selector, .signup__data-container--input{
				width: 200px !important;
				padding: 8px !important;
				background-color: #f6f6f6 !important;
				transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				border: 1px solid #CACACA !important;
				border-radius: 4px;
				height: 40px;
			}
			.react-datepicker-wrapper{
				width:50%;
			}
			.react-datepicker__input-container{
				height: 40px;
			}
			.react-tel-input{
				width: auto !important;
			}
			.react-datepicker-wrapper{
				height: 40px;
			}
			.signup__data-container--inputPhone{
				width: 200px !important;
				padding: 8px 8px 8px 48px !important;
				background-color: #f6f6f6 !important;
				transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
				border: 1px solid #CACACA !important;
				width: 85%;
				height: 40px;
			}
			.signup__selector:focus, .signup__data-container--input:focus{
				color: #495057;
				background-color: #fff;
				border-color: #80bdff;
				outline: 0;
				box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
			}
		}
		.signup__data-container--switcher {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
		}
	}
	.signup__data--btn{
		display: flex;
		justify-content: center;
		margin: 20px auto;
	}
	.signup__data-accountData{
		width: 400px;
		margin: 0 auto;
		padding: 25px 15px;
		border: 1px solid #CACACA;
		border-radius: 4px;
		box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
	}
	.button {
		display: block;
		margin: 10px auto;
	}	
	.signup__btns{
		display: flex;
		justify-content: center;
	}
}

