@use '@/styles/global/Vars.scss';

.studiesOrder {
	padding: 6px 0 0 0;
	flex-direction: column;
	justify-content: space-between;
	align-items: flex-start;
	flex-wrap: nowrap;
	justify-content: flex-start;
	background-color: Vars.$white-color;
	border-radius: 6px;
	padding-bottom: 15px;
	overflow-y: auto;
	.file_btn {
		color: #fff;
		position: absolute;
		top: 1px;
		right: 10px;
		font-size: 22px;
		cursor: pointer;
	}
	.file__title {
		width: 100%;
		margin: 0;
		padding: 0;
		text-align: center;
		background-color: Vars.$deep-blue-b;
		border-radius: 6px 6px 0 0;
		height: 35px;
		color: #fff !important;

		.cursor {
			width: inherit;
			height: inherit;
			font-weight: normal;
			display: flex;
			align-items: center;
			justify-content: center;
			cursor: pointer;
			color: #fff !important;
		}
	}
	header{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 8px;
		.quit_btn{
			cursor: pointer;
			svg{
				color: #0A6DD7;
				font-size: 1.5rem;
			}
		}
	}
}
.studiesOrder__especifications{
		padding: 8px;
		label{
			font-weight: bold;
		}
	}
.studiesOrder__container {
	width: 100%;
	position: relative;
	.studiesOrder__container--checklist{
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 5px;
		.studiesOrder__item--checklist{
			width:10%
		}
	}
	.studiesOrder__inputContainer {
		width: 100%;
		padding: 5px 10px;
		display: block;
		.studiesOrder__container--input {
			text-align: left;
			padding-left: 5px;
			font-size: 14px;
			height: 30px;
			width: 100%;
		}
		.addButtonContainer{
			width: 100%;
			display: flex;
			justify-content: center;
		}
	}
	.studiesOrder__container--list {
		max-height: 200px;
		overflow-y: auto;
		overflow-x: hidden;
		&::-webkit-scrollbar {
			width: 8px;
		}
		&::-webkit-scrollbar-thumb {
			background-color: Vars.$header-color;
			width: 8px;
		}
		&::-webkit-scrollbar-track {
			background-color: #d0dae2;
		}
		li {
			display: flex;
			align-items: center;
			margin-top: 3px;
			border-bottom: 1px solid rgba($color: #000000, $alpha: 0.65);
			width: 80%;
			margin-left: auto;
			margin-right: auto;
			small {
				width: 100%;
			}
			button{
				width: 40px;
				height: 35px;
			}
		}
	}
	
}

.studiesOrder__container--submit{
		width: 100%;
		button{
			display: block;
			margin: 15px auto;
		}
}


.studies__studiesOS{
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 5px 0;
	.studies__studiesOS--inputContainer{
		display: flex;
		flex-direction: column;
		width: 30%;
		padding: 5x 15px;
		border-radius: 5px;
		border: 1px solid #CACACA;
		input{
			background: #fff !important;
			height: 40px !important;
			border-radius: 15px;
			text-align: left;
		}
	}
	.obrasocial{
		width: 40%;
		.obrasocial_options{
		display: flex;
		align-content: center;
		.studies__container--input{
			width: 70%;
		}
		.obrasocial_checkbox{
			display: flex;
			flex-direction: row-reverse;
			align-content: center;
			justify-content: center;
				label{
				margin-bottom: 0;
				padding-top: 8px;
				}
				input{
					margin: 0 5px;
				}
			}
		}

	}
}