.container__transcription{
  position: relative;
  max-height: 350px;
  height: 100%;
  overflow-y: auto;
 }

 .microphone {
  display: grid;
  place-content: center;
  width: 47px;
  height: 47px;
  border-radius: 100%;
  cursor: pointer;
 }

 .stopListening {
   background-color: #0a6dd6;
   color: #fff;
 }

 .editTranscription {
  padding-right: 25px;
 }

 .container__buttonsStt {
   display: flex;
   align-items: center;
   gap: 0 20px;
   padding: 0 20px;
 }



 .flexEnd {
  justify-content: flex-end;
 }

 .spaceBetween {
  justify-content: space-between;
 }

 .container_mic{
   margin-bottom: 10px;
   width: 40px;
   height: 40px;
   border-radius: 50%;
   position: relative;
 }



