.resumenItem {
  list-style-type: initial;
 }

 .resumenList {
  margin: 0 20px;
 }

 .container__resumen {
  max-height: 464px;
  overflow-y: auto;
 }

 .containerTitle_resumen{
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding-right: 20px;
 }

 .clipboardIcon{
   cursor: pointer;
   transition: all 0.2s;
 }
 .clipboardIcon:hover{
   transform: scale(1.2);
 }