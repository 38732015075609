@use '../../../../styles/global/Vars.scss';

.rightColumnContainer{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.rightColumnContainerEmpty{
    width: 45%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.rightColumnFooter{
    display: flex;
    gap: 8px;
}

.contentActions{
    height: 90%;
}

.attActions{
    display: flex;
    flex-direction: column;
    gap: 2px;
    height: 95%;
    overflow-y: scroll;
}
.loaderContainer{
    display: flex; 
    flex-direction: column;
    align-items: center; 
    justify-content: center; 
    height: 100%; 
}