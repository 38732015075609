@use '@/styles/global/Vars.scss';

.historyDetail{
	overflow-y: auto;
	border-radius: 8px;
	svg {
		color: Vars.$color-text;
		font-size: 1.6rem;
		margin: 0 8px;
		cursor: pointer;
	}
	.historyContent {
		padding: 8px;
	}
	.historyDetail__row{
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		span{
			padding: 5px;
			strong{
				margin-right: 4px;
			}
		}
		&::after{
		content: "";
			width: 95%;
			margin: 0 auto;
			border-bottom: 1px solid rgba(0,0,0,0.2);
	}
	.historyDetail__rowReceta{
		display: flex;
		.historyDetail__rowReceta--index{
		span{
			padding: 10px;
			display: block;
		}
		}
		.historyDetail__rowReceta--data{
		span{
			padding: 10px 10px 10px 0;
			i{
			font-weight: bold;
			}
		}
		}
	}
	}

	&::-webkit-scrollbar {
		width: 10px;
		height: 90%;
		margin-right: 15px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: rgba(0,0,0,0.4);
		width: 8px;
		border-radius: Vars.$regular-borders;
	}
	&::-webkit-scrollbar-track {
		background-color: transparent;
	}
}
