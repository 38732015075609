@use '@/styles/global/Vars.scss';
 
.notificationBox__container {
	width: 100%;
	overflow: auto;
	z-index: 100;
	overscroll-behavior-y: contain;
	&::-webkit-scrollbar {
		width: 10px;
	}
	&::-webkit-scrollbar-thumb {
		background-color: #acacac;
		width: 12px;
		}
	&::-webkit-scrollbar-track {
		background-color: #d0dae2;
		width: 10px;
	}
}

.notification__notification{
 position: relative;
 cursor: pointer;
 color: #1c1e21;
 border: 1px solid rgba(0,0,0,0.1);
 .notification__notification--content{
		 display: flex;
		 justify-content: space-evenly;
		 align-items: center;
		 padding: 6px;
		 border-bottom: 2px solid white;
		 &:hover{
			background-color: rgba(0,0,0,0.1);
		 }
	.notification__notification--img{
		 width: 75px;
		 height: 75px;
		 border: 1px solid rgba(0,0,0,0.15);
		 border-radius: 50%;
		 margin-right: 10px !important;
		 img{
		 width: 100%;
		 height: 100%;
		 object-fit: cover;
		 margin: 0;
		 border-radius: 50%;
		 }
	}
	.notification__notification--text{
		 width: calc(100% - 85px);
		 position: relative;
		 h6{
		 font-weight: bold;
		 text-transform: none;
		 color: #1c1e21;
		 }
		 span{
		 text-transform: none;
		 font-weight: 200;
		 }
	.notification__notification--unread{
		 background-color: Vars.$pink;
		 position: absolute;
		 left: 90%;
		 top: 40%;
		 width: 12px;
		 height: 12px;
		 border-radius: 50%;
		 }
	}
 }
 &.read{
	background-color: Vars.$gray-body-background;
 }
} 

.notification{
 .notification__container{
 display: flex;
 justify-content: space-between;
 }
}

.subtitle {
 color: Vars.$whater-blue;
 border: 1px solid Vars.$gray-navigation-color; 
 padding: 10px;
 border-radius: 5px;
 cursor: pointer;
}

@media (max-width: 768px) {
	.header__icons {
		width: 100%;
		justify-content: center;
	}
}
 
 .profileMenu__background{
	position: absolute;
	top: 5px;
	left: 5px;
	width: 97%;
	height: 90%;
	z-index: 1;
	border-radius: 6px;
 }
 .profileMenu__background:hover{
	background: rgba(0, 0, 0, 0.05);
 }
 
 .notificationBox_container{
	h5{
		 font-size: 17px;
		 text-align: left;
		 padding: 10px 0px;
	}

 }
 
 .profileBox_container {
	position: relative;
	display: flex;
	justify-content: flex-start;
	align-items: center;
	padding: 15px;
	cursor: pointer;
	img {
	 margin: 5px 8px;
	 width: 40px;
	 height: 40px;
	}
	.profile_NameData{
	 display: flex;
	 flex-direction: column;
	 span:nth-child(1){
		text-transform: none;
		font-size: 1rem;
		color: Vars.$color-text;
	 }
	 span:nth-child(2){
		 text-transform: none;
		 font-size: .9375rem;
		 color: #65676B;
	 }
	}
 }

 .profileBox_item{
	position: relative;
	display: flex;
	align-items: center;
	padding: 15px;
	cursor: pointer;
	svg{
	 font-size: 1rem;
	 background-color: Vars.$gray-body-background;
	 width: 40px;
	 height: 40px;
	 padding: 8px;
	 text-align: center;
	 margin-right: 8px;
	 border-radius: 50%;
	}
 }
 
 .fontSize{
	margin: 5px;
	border-radius: 6px;
	text-transform: none;
	span{
	 font-size: 0.9rem;
	 font-weight: thin;
	 margin-left: 10px;
	}
 }
 
 .fontSize:hover{
	background-color:rgba(0, 0, 0, 0.05) ;
 }
 
 
 .notificationBox{
	max-height: 80vh;
	overflow: auto;
	&::-webkit-scrollbar {
	 width: 10px;
	}
	&::-webkit-scrollbar-thumb {
	 background-color: #acacac;
	 width: 12px;
	}
	&::-webkit-scrollbar-track {
	 background-color: #d0dae2;
	 width: 10px;
	}
	h3{
	 font-weight: bold;
		text-align: left;
	}
	.notificationBox_container{
	 h5{
		font-size: 17px;
		font-weight: bold;
		text-align: left;
		padding: 10px 16px;
	 }
	}
 }
 
 
 .notification_notification{
	position: relative;
	cursor: pointer;
	.notification_notification--content{
	 display: flex;
	 justify-content: space-evenly;
	 align-items: center;
	 padding: 12px 6px;
	 border-top: 1px solid #CACACA ;
	 .notification_notification--img{
		width: 50px;
		height: 50px;
		border: 1px solid Vars.$lightgray_separator;
		border-radius: 50%;
		margin-right: 10px !important;
		img{
			 width: 100%;
			 height: 100%;
			 object-fit: cover;
			 margin: 0;
			 border-radius: 50%;
		}
	 }
	 .notification_notification--text{
		width: calc(100% - 85px);
		position: relative;
		h6{
			 font-weight: bold;
			 text-transform: none;
		}
		span{
			 text-transform: none;
			 font-weight: 200;
		}
		.notification_notification--unread{
			 background-color: Vars.$pink;
			 position: absolute;
			 left: 90%;
			 top: 10%;
			 width: 12px;
			 height: 12px;
			 border-radius: 50%;
		}
	 }
	}
	&.read{
	 background-color: Vars.$gray-body-background;
	}
 }


 