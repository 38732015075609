@use '@/styles/global/Vars.scss';

.emptyState__container{
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-top: 20px;
	height: 70vh;
	.emptyState__img{
		margin: 20px auto;
		width: 190px;
	}
	.emptyState__title{
		color: Vars.$uma-primary;
		text-align: center;
		font-size: 1.3rem;
		margin: 18px;
	}
	.emptyState__links {
		margin: 10px 0;
		svg {
			margin: 0 15px;
			font-size: 3rem;
			cursor: pointer;
		}
	}
	.emptyState__btn{
		padding: 25px;
		width: 200px;
		margin: 20px;
	}
}
