/* OCCIPITAL NEW */
/* Brand colors */
$color-primary: #3562FF;
$color-primary--hover: #002DE3;
$color-primary--pressed: #C4C8FE;
$color-secondary: #A13DDF;
$color-secondary--hover: #6412CB;
$color-secondary--pressed: #DEBCF3;
$color-tertiary: #FFD300;
$color-tertiary--hover: #FFD300;
$color-tertiary--pressed: #FFF8BF;
/* Text colors */
$color-text-primary: #323338;
$color-text-secondary: #6F6F6F;
$color-text-link: #3562FF;
$color-text-error: #E71D1D;
/* Background color */
$color-background-light: #FEFEFE;
/* State Colors */
$color-state-sucess: #259B38;
$color-state-error: #E71D1D;
$color-state-warning: #FFB700;
/* State Colors Hover */
$--color-state-sucess-hover: #268a37;
$--color-state-error-hover: #d22525;
$--color-state-warning-hover: #e4a60a;
/* Greys (not gays) */
$color-grey-1: #333333;
$color-grey-2: #4F4F4F;
$color-grey-3:#828282;
$color-grey-4: #BDBDBD;
$color-grey-5: #E0E0E0;
$color-grey-6: #F2F2F2;

/* UMA New Vars */
$uma-primary: #3562FF; 
$uma-primary-light: #C4C8FE;
$uma-primary-dark: #002DE3;
$uma-secondary: #A13DDF;
$uma-secondary-light: #DEBCF3;
$uma-secondary-dark: #6412CB;
$uma-tertiary: #FFD300; 
$uma-tertiary-light: #FFF8BF;
$uma-tertiary-dark: #FFD300;
$uma-background: #FEFEFE;
$uma-pink: #E9008C;
$uma-text: #323338;
$uma-text-disabled: #6F6F6F;
$uma-text-link: #3562FF;
$uma-text-error: #E71D1D;
$uma-text-base: #323338;
$white-color: #FEFEFE;
$correct: #259B38;
$correct-disabled: #80C7A1;
$uma-input: #B6B6B644;
$uma-accent-color: #6048BF;
$uma-accent-disabled-color: rgba(7, 6, 9, 0.6);
$uma-error: #E71D1D;
$uma-button: #0A6DD7;

/* Colors */
$primary-color: #0A6DD7;
$header-color: #0071f2;
$primary-background: #42a5f5;
$secondary-background: #1565c0;
$whater-blue: #4fa1d1;
$deep-blue: #265985;
$deep-blue-b: #0083e2;
$background-transparency: rgba(173, 216, 230, 0.9);
$warning-transparency: rgba(255, 204, 0, 0.719);
/* Buttons */
$blue-button: #42a5f6;

$primary-cyan: #3fd6d2;

$white-color: #FEFEFE;
$gray-font: #696969;
$extra-gray: #a8c3dc;
$gray-navigation-color: #c6dbe8;
$gray-body-background: #ebebeb;
$special-grey: #6c757d;
$modal-back: #5a5a5a;
$data-grey: #cacaca;
$intense-gray: #5C5C5C;
$text-dark: #6f6f6f;
$text-light: #8a8a8a;
$lighter-grey: #b6b6b6;

$green-high: #55DAD7;
$green-low: #deffb3;
$green-uma: #36a853;

$pink: #dc69a9;
$red-high: #ef5350;
$red-low: #deffb3;

$yellow-high: #ffcc00;
$orange-high: #ff9933;

$regular-borders: 6px;

/* inputs - btn */
$light: #f6f6f6;
$dark-color: #0d0d0d;
$blue-btn: #0A6DD7;
$blue-hover-btn: #3981cf;
$blue-input-border: #5fbae9;
$light-input-placeholder: #b6b6b6;
/* Shadows */
$main-shadow: 0 1px 4px rgba(239, 239, 239, 0.16), 0 1px 4px rgba(0, 0, 0, 0.23);
$box-shadow: 0 12px 28px 0 rgba(0,0,0,0.2),0 2px 4px 0 rgba(0,0,0,0.10),inset 0 0 0 1px rgba(255, 255, 255, 0.5);
$light-shadow: 0 10px 30px -3px rgba(0,0,0,0.08),0 4px 6px -2px rgba(0,0,0,0.05);
$tune-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25), 0 30px 40px -3px rgba(0,0,0,0.08),0 15px 10px -2px rgba(0,0,0,0.05);
/* New styles */
$green-low: #deffb3;
$buttons-green: #2bbbad;
$primary-blue: #97c1d3;
$primary-cyan: #3fd6d2;
$primary-orange: #f8bd1d;
$yellow-high: #ffcc00;
$orange-high: #ff9933;
$violet-high: #5c5286;
$violet-gradient: #C1CDE4;
$color-text: #1E1E1E;
$color-text-black: #252733;
$color-text-lighter: #9696A0;
$lightgray_separator:rgb(235, 237, 240);
$main-back-gradient: linear-gradient(45deg, $white-color 1%, rgba(65, 204, 201, 0.2) 20%, $white-color) !important;
$when-dark: #516271;
$pediatric-marker: #4de1be;
