@use '@/styles/global/Vars.scss';

.emptyAppointments__container {
	display: flex;
	flex-direction: column;
	align-items: center;

	img {
		margin: 50px 0;
		width: 150px;
	}

	h3 {
		font-weight: 600;
		color: Vars.$uma-primary;
		font-size: 1.2rem;
	}

	.emptyAppointments__button {
		background-color: #0A6DD7;
		display: inline-block;
		border-radius: 15px;
		border: none;
		margin: 15px auto;
		text-align: center;
		font-size: 1rem;
		cursor: pointer;
		color: white;
		font-weight: bold;
		padding: 15px;
		width: 200px;
		outline: none;
	}
}

.patientCard__container {
	display: grid;
	grid-template-columns: 1fr 2fr 1.5fr;
	grid-template-rows: 120px;
	justify-content: center;
	align-items: flex-start;
	border-radius: 5px;
	margin: 5px 0 10px 10px;
	background: Vars.$white-color;
	padding: 20px 10px;

	@media (max-width: 830px) {
		grid-template-columns: 0.5fr 1fr;
	}

	.appointmentActions__container {
		position: relative;
		display: grid;
		grid-template-columns: 1fr 1fr 1fr;
		grid-template-rows: 120px;
		align-items: center;
		text-align: center;
		font-weight: bold;

		@media (max-width: 1024px) {
			justify-content: flex-start;
		}

		.actionIcon-container {
			display: flex;
			flex-direction: column;
			justify-content: space-around;
			cursor: pointer;

			p {
				margin-top: 4px;
				line-height: 1rem;
				font-size: 15px;
				font-weight: 600;

				@media (max-width: 1024px) {
					font-size: 12px;
				}
			}
		}

		.actionIcon-container:nth-child(3):hover+.patientAppointment__tooltip {
			visibility: visible;
			opacity: 1;
			transition: 0.3s opacity ease;
			z-index: 2;
		}

		.actionIcon {
			margin: 0 auto;
			cursor: pointer;
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			border-radius: 50%;
			transition: all 0.3s;
			border: 1px solid Vars.$uma-primary;

			&.callIcon {
				background-color: #27B463;
				border: transparent;
			}

			&.disableCallIcon {
				background-color: grey;
				border: transparent;
				opacity: 0.5;
			}

			&.attachIcon {
				color: Vars.$uma-primary;
				transform: rotate(-90deg);
			}

			&.pediatric {
				background: #f5f5f5;
				color: Vars.$pediatric-marker;

				&:hover {
					border: 2px solid Vars.$white-color;
					background: Vars.$buttons-green;
					color: #f5f5f5;
				}
			}

			&.pending-payment {
				background-color: Vars.$orange-high;
				color: white;

				&:hover {
					color: Vars.$orange-high;
					background-color: white;
					border: 2px solid Vars.$orange-high;
				}
			}

			&.pending-validation {
				background-color: Vars.$uma-secondary-light;
				color: #fff;

				&:hover {
					color: Vars.$uma-secondary-light;
					background-color: white;
					border: 2px solid Vars.$uma-secondary-light;
				}
			}
		}

	}

}

.specialist_container {
	position: relative;
	top: 0;
	left: 0;
	margin-top: 10px;
	border-radius: 5px;
	box-shadow: Vars.$main-shadow;
	padding: 16px;
	overflow: hidden;
	.specialist_today {
		background-color: Vars.$uma-background;
		color: Vars.$uma-primary;
		font-size: 24px;
		font-weight: bold;
		text-align: start;
		padding-left: 10px;
	}

	.background_container {
		z-index: 0;
		margin-top: 10px;
		background: Vars.$uma-primary;
		border-radius: 5px;
		box-shadow: 40px Vars.$main-shadow;
		&.pediatric {
			background: Vars.$pediatric-marker;
		}

		&.especialista_online {
			background: Vars.$uma-secondary;
		}

		&.online {
			&.PRESENT {
				background: Vars.$uma-primary;
			}

			&.ABSENT {
				background: Vars.$primary-blue;
			}
		}
	}
}

.background_container {
	z-index: 0;
	margin-top: 10px;
	background: Vars.$uma-primary;
	border-radius: 5px;
	box-shadow: Vars.$main-shadow;
	&.pediatric {
		background: Vars.$pediatric-marker;
	}

	&.especialista_online {
		background: Vars.$uma-secondary;
	}

	&.online {
		&.PRESENT {
			background: Vars.$uma-primary;
		}

		&.ABSENT {
			background: Vars.$primary-blue;
		}
	}

	&.consultorio {
		margin: 4px;

		&.PRESENT {
			background: Vars.$uma-primary;
		}

		&.ABSENT {
			background: Vars.$primary-blue;
		}
	}

}

.status__container {
	background-color: Vars.$uma-background;
	padding: 8px 8px;
	display: flex;
	justify-content: space-around;
	border: 1px solid Vars.$data-grey;
	border-radius: 4px;
	transition: 1s all ease;
	box-shadow: 0px 2px 4px 1px rgba(99, 99, 99, 0.25);
	flex-wrap: wrap;
	.status_info {
		p {
			max-width: 200px;
			text-align: center;
		}
	}

	.status__ref {
		display: flex;
		justify-content: space-between;
		align-items: center;
		min-height: 20px;
		border-radius: 15px;
		padding: 5px;
		cursor: pointer;
		border: none;

		span {
			margin: 0 5px;
			border: 1px solid Vars.$data-grey;
			width: 15px;
			height: 15px;
			border-radius: 50%;
			display: block;
		}

		.all {
			background-color: white;
			color: Vars.$uma-text;
			border: 1px solid Vars.$data-grey;
		}

		.guardia {
			background-color: Vars.$color-primary;
			color: white;
			border: 1px solid Vars.$data-grey;
		}

		.online {
			background-color: Vars.$uma-secondary;
			color: white;
		}

		.especialista_online {
			background-color: Vars.$uma-secondary;
			color: white;
		}

		.pediatric {
			background-color: Vars.$pediatric-marker;
			color: white;
		}

		&.today {
			background: Vars.$color-primary;
		}

		label {
			margin: 3px auto;
			display: block;
			text-align: center;
			color: Vars.$uma-text;
			font-weight: 700;
			cursor: pointer;
		}

		.length {
			display: inline-block;
			min-width: 25px;
			height: 25px;
			padding: 2px 5px;
			text-align: center;
			margin: 0 4px;
			border-radius: 5px;
			line-height: 1.3;
		}
	}

	.status__ref {
		transition: 0.4s all ease;

		&.online {
			background: rgba(Vars.$uma-secondary, 0.3);
		}

		&.pediatric {
			background: rgba(Vars.$pediatric-marker, 0.3);
		}

		&.visita-medica {
			background: rgba(Vars.$uma-primary, 0.3);

		}

		&.especialista_online {
			background: rgba(Vars.$uma-secondary, 0.3);
		}

		&.consultorio {
			background: rgba(Vars.$uma-secondary, 0.3);

		}

		&.all {
			background: rgba(Vars.$uma-text, 0.3);
		}

		&.guardia {
			background: rgba(Vars.$uma-primary, 0.3);
		}

		&.today {
			background: rgba(Vars.$color-primary, 0.3);
		}
	}

	.status__ref:hover {
		transition: 0.4s all ease;

		&.online {
			background: rgba(Vars.$uma-secondary, 0.45);
		}

		&.pediatric {
			background: rgba(Vars.$pediatric-marker, 0.45);
		}

		&.visita-medica {
			background: rgba(Vars.$uma-primary, 0.45);
		}

		&.especialista_online {
			background: rgba(Vars.$uma-secondary, 0.45);
		}

		&.consultorio {
			background: rgba(Vars.$uma-secondary, 0.45);

		}

		&.guardia {
			background: rgba(Vars.$uma-primary, 0.45);
		}

		&.all {
			background: rgba(Vars.$uma-text, 0.45);
		}

		&.today {
			background: rgba(Vars.$color-primary, 0.3);
		}
	}

	.active:hover {
		background-color: rgba(0, 0, 0, 0.2);

	}
}



.patientAppointment__tooltip {
	visibility: visible;
	display: block;
	opacity: 0;
	position: absolute;
	width: 250px;
	top: 70%;
	z-index: 0;
	right: 0%;
	background: #fff;
	box-shadow: 0 12px 28px 0 rgba(0, 0, 0, 0.2), 0 2px 4px 0 rgba(0, 0, 0, 0.10), inset 0 0 0 1px rgba(255, 255, 255, 0.5);
	;
	overscroll-behavior-y: contain;
	border-radius: 6px;
	color: Vars.$uma-text;
	padding: 4px;
	border: 1px solid Vars.$uma-secondary;

	ul {
		padding: 8px 16px;
		text-align: left;

		li {
			list-style: circle;
			margin-bottom: 16px;
		}
	}
}
