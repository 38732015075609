@use '@/styles/global/Vars.scss';

.historyContainer{
	max-height: 40vh;
	overflow-y: auto;
	padding: 0 8px;
	&.empty {
		height: 10vh;
	}
	.History_filterNav{
		display: flex;
		justify-content: flex-start;
		label{
			color: #343434;
			font-weight: 300;
			font-size: 14px;
			line-height: 22px;
			cursor: pointer;
			select{
				border:1px #CACACA solid !important;
			}
		}
		.historico_filter{
		margin-left: 30px;
		padding-top: 6px;
		cursor: pointer;
		i{
			margin: 0 7px;
			color: #818181;
			font-size: 18px;

		}
	}
}
.historico_emptyHistorico{
	margin-top: 20px;
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	margin-bottom: 8px;
	p{
		text-align: center;
		font-size: 1.2rem;
	}
}
.timeline-card-content{
	padding: 10px;
	.show-more{
	display: none;
}
}

.cardsCitas_container{
	position: relative;
	border-radius: 5px;
	display: flex;
	flex-direction: column;
	align-items: center;
	.card{
		width: 100%;
		position: relative;
		background: #FFFFFF;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: 1px solid #CACACA;
		box-sizing: border-box;
		border-radius: 6px;
		box-shadow:0 .125rem .25rem rgba(0,0,0, .075);
		padding: 8px;
		margin-bottom: 8px;
		.card_mainRow_provider{
			width: 30%;
			overflow-x: hidden;
			text-align: center;
			padding: 0 5px;
			.mainRow__specialty{
				font-size: 0.9rem;
				text-transform: capitalize;
				margin: 0 auto;
			}
			.mainRow__provider{
				font-size: 0.8rem;
				text-transform: capitalize;
				color: rgb(85, 88, 90);
			}
		}
		.card_mainRow_data{
			display: flex;
			flex-direction: column;
			padding: 0 8px;
			width: 55%;
			font-size: 0.8rem;
			label{
				font-weight: 600;
				color: #2b3035;
				.card_mainRow_data_text{
					font-weight: 200 ;
					color: rgb(85, 88, 90);
					p {
						overflow-wrap: break-word;
						word-wrap: break-word;
						hyphens: auto;
						max-width: 100%;
					}

				}
				.card_mainRow_data_detailsbtn{
					color:  Vars.$uma-primary;;
					margin: 0 5px;
					cursor: pointer;
				}
			}
		}
		.card_mainRow_details{
			cursor: pointer;
			display: flex;
			align-items: center;
			padding: 5px 7px;
			border-radius: 4px;
			transition: all 0.3ms;
			flex-direction: column;
			justify-content: center;
			svg {
				color: Vars.$uma-primary;
				font-size: 1.3rem;
			}
			span {
				color: Vars.$uma-primary;
				font-weight: 700;
			}
		}
		.card_mainRow_details:hover{
			background-color: rgba(41, 141, 247, 0.2);
		}
	}
	.card:not(:first-of-type)::after{
	content: "";
	position: absolute;
	top: calc(0% - 13px);
	right: 50%;
	width: 25px;
	box-shadow: 0 0 0 0.8px rgba(0, 0, 0, 0.15);

	transform: rotate(90deg);
	border-radius: 0;
	}
}
}
.searchHistory{
	margin: 5px 0;
	position: relative;
}
.clear-input{
	position: absolute;
	right: 15px;
	top: 25px;
	cursor: pointer;
}