/* phoneValidation.module.scss */
.phone-validation {
  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  &__image{
    width: 10%;
  }

  &__container-style {
    width: 80%;
  }

  &__button-container {
    width: 40%;
  }

  &__pin-field-error {
    color: red;
  }

  &__countdown-button {
    width: 100%;
  }
}
